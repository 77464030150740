import React from 'react';
import { Router } from '@reach/router';

import Dashboard from '../../Dashboard';
import Book from '../../Book';
import TokenCheck from './TokenCheck';
import StorySelector from '../../Dashboard/scenes/StorySelector';

const AppRouter = () => {
    return (
        <Router>
            <TokenCheck path='/'>
                <Dashboard path='/' default />
                <Book path='/book/:bookId' />
                <StorySelector path='/selectStory'/>
            </TokenCheck>
        </Router>
    )
}

export default AppRouter;