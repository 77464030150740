import React, { useState, useContext } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import styles from '../Dashboard.module.scss';
import EditorJsOutput from '../../../components/EditorJsOutput'
import { Book } from "react-feather";
import boomer from '../../../images/loader-boomer.png';
import { FILTERED_TEMPLATES, ADD_BOOK_TEMPLATE_CHOICE } from "../services/graphql";
import { FormErrors, Loader, Modal } from "@axeedge/go-pupil-components";
import { AuthContext } from "../../../services/user/AuthProvider";
import { navigate } from "@reach/router";
import styles2 from '../../Book/components/Chapter/components/Guidance/Guidance.module.scss';


const StorySelector = () => {


    const { currentUser: user } = useContext(AuthContext);

    const renderChapterContent = (starter) => {
        try {
            return JSON.parse(starter.content);
        } catch (e) {
            return {
                blocks: [
                    {
                        type: 'paragraph',
                        data: {
                            text: starter.content
                        }
                    }
                ]
            }
        }
    }
    const [selectedStory, setSelectedStory] = useState(null);
    const [confirmStory, setConfirmStory] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [showVoted, setShowVoted] = useState(false);

    const { data, loading, error } = useQuery(FILTERED_TEMPLATES, {
        variables: {
            isIspChoice: true
        }
    });

    const [addBookTemplateChoice, { loading: adding }] = useMutation(ADD_BOOK_TEMPLATE_CHOICE, {
        update: (_, { data }) => {
            if (data.addBookTemplateChoice?.errors?.length > 0) {
                setFormErrors(data.addBookTemplateChoice.errors)
                return
            }
            if (data?.addBookTemplateChoice?.bookTemplateChoice?.id) {
                setShowVoted(true);
            }
        }
    })

    if (loading) {
        return <Loader />
    }
    if (error) {
        return <p>{error.message}</p>
    }

    if (data?.filteredBookTemplates) {

        const templates = data?.filteredBookTemplates || [];

        return (
            <>
                <div className={styles.dashboard}>
                    <div className={styles.dashboardMain}>
                        <div className='content-panel'>
                            {selectedStory ?
                                <>
                                    <h2 className="u-text-center heavy u-mb-3">{selectedStory.name}</h2>
                                    {selectedStory.chapters[0] && <EditorJsOutput data={renderChapterContent(selectedStory.chapters[0])} />}
                                </> :
                                <p>Select a story start to read</p>
                            }
                        </div>
                    </div>
                    <div className={styles.dashboardSide}>
                        <div className={styles2.guidance}>
                            <div className={styles2.guidanceAvatar}>
                                <img src={user.boomerAvatarUrl ? `${user.boomerAvatarUrl}?v=${user.updatedAt.replace(/\s/g, '')}` : boomer} className={styles.guidanceAvatarImg} />
                            </div>
                            <div className={styles2.guidancePanel}>
                                <p>Hi {user.firstName}! Welcome to BoomWriter. Please read through each story start and select the one you wish to respond to by clciking “confirm starter”</p>
                            </div>
                        </div>

                        <div className='content-panel u-p-3 u-mt-4'>
                            <h3 className="u-text-center heavy u-mt-2 u-mb-3">Select a story start</h3>
                            <ul className="u-mb-2">
                                {templates.map(template =>
                                    <li key={template.id} className="u-mb-1">
                                        <button onClick={() => setSelectedStory(template)} className={`${styles.selectStoryBtn} ${selectedStory?.id === template.id ? styles.selectStoryBtnActive : ''}`}>
                                            <Book className='text-white u-mr-1' /> {template.name}
                                        </button>
                                    </li>
                                )}
                            </ul>
                            <button onClick={() => setConfirmStory(selectedStory)} className="button button-fullWidth">
                                Confirm starter
                            </button>
                        </div>
                    </div>
                </div>

                {confirmStory && (
                    <Modal closeModal={() => setConfirmStory(null)}>
                        <div className="u-text-center">
                            {showVoted ?
                                <>
                                    <p className="u-m-base-3 lead">Your selection has been saved and you will be added to a group of students who have also chosen this story. The writing will begin soon</p>

                                    <button
                                        onClick={() => navigate('/')} className="button button-red">Close</button>
                                </> :
                                <>
                                    <p className="u-mb-2 lead heavy">Are you sure you want to confirm this as your story starter?</p>
                                    <p className="u-mb-2 lead">{confirmStory.name}</p>
                                    {formErrors && <FormErrors errors={formErrors} />}
                                    <button
                                        disabled={adding}
                                        onClick={() => addBookTemplateChoice({
                                            variables: {
                                                studentId: user.id,
                                                bookTemplateId: confirmStory.id
                                            }
                                        })} className="button u-mr-2">Yes</button>

                                    <button
                                        onClick={() => setConfirmStory(null)} className="button button-red">No</button>
                                </>
                            }
                        </div>
                    </Modal>
                )}

            </>
        )
    }
    return null;
}

export default StorySelector;